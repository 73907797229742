export default [
  {
    path: "/tryout/",
    name: "tryout",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
  {
    path: "/tryout/utbk",
    name: "tryout-utbk",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout UTBK",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
  {
    path: "/tryout/skd",
    name: "tryout-skd",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout SKD",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
  {
    path: "/tryout/cpns",
    name: "tryout-cpns",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout CPNS",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
  {
    path: "/tryout/tpa",
    name: "tryout-tpa",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout TPA",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
  {
    path: "/tryout/tbi",
    name: "tryout-tbi",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout TBI",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
  {
    path: "/tryout/",
    name: "tryout-bumn",
    meta: {
      pageTitle: "Tryout",
      breadcrumb: [
        {
          text: "Tryout BUMN",
          active: true,
        },
      ],
    },
    component: () => import("@/views/dashboard/manajemen-try-out/index.vue"),
  },
];
